/* eslint-disable react/require-default-props */
import React, { forwardRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider, useFormContext, useFormState } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import isEqual from 'lodash/isEqual'

import { Button } from '@/components/common'
import usePrevious from '@/hooks/usePrevious'

const Form = forwardRef(({
  children,
  initialValues,
  onSubmit,
  options,
  schema,
  enableReinitialize = false,
  ...rest
}, ref) => {
  const methods = useForm({
    mode: 'onSubmit',
    ...options,
    defaultValues: initialValues,
    ...schema ? { resolver: yupResolver(schema) } : {},
  })

  const prevInitialValues = usePrevious(initialValues)
  useEffect(() => {
    if (enableReinitialize && prevInitialValues && !isEqual(initialValues, prevInitialValues)) {
      methods.reset(initialValues)
    }
  }, [initialValues, methods.reset, enableReinitialize])

  return (
    <FormProvider {...methods}>
      <form ref={ref} noValidate onSubmit={methods.handleSubmit(onSubmit)} {...rest}>
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
})

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  schema: PropTypes.shape({}),
  enableReinitialize: PropTypes.bool,
}

const FormAction = forwardRef(({
  disabled = false,
  disabledIfInvalid = false,
  messageId,
  submittingMessageId =
  messageId,
  ...rest
}, ref) => {
  const { formState: { isSubmitting } } = useFormContext()
  const { isValid } = useFormState()

  return (<Button
    ref={ref}
    disabled={disabled || isSubmitting || (disabledIfInvalid && !isValid)}
    messageId={isSubmitting ? submittingMessageId : messageId}
    {...rest}
  />)
})

FormAction.propTypes = {
  disabled: PropTypes.bool,
  disabledIfInvalid: PropTypes.bool,
  messageId: PropTypes.string,
  submittingMessageId: PropTypes.string,
}

Form.Action = FormAction

export default Form
