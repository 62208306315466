import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { DialogsRenderer } from '@theneatcompany/nui'

import { useSignUp } from '@/components/Providers/SignUpProvider'
import Seo from '@/components/layout/Seo'
import TopBar from '@/components/layout/TopBar'
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer/Footer'
import CookieConsent from '@/components/CookieConsent'
import ScrollToTopButton from '@/components/layout/ScrollToTopButton'
import RecaptchaTerms from '@/components/common/RecaptchaTerms'

import styles from './Layout.module.scss'

const Layout = ({
  children,
  noNavFooter = false,
  showFooter = true,
  shouldRobotsIndex = true,
  showTopBar = true,
  showHeader = true,
  standalone = false,
  logoOnlyHeader = false,
  pageId,
}) => {
  const { bannerDescription } = useSignUp()

  useEffect(() => {
    let didAdd = false

    if (standalone || (!showHeader && !showFooter)) {
      document.querySelector('body')?.classList.add('standalone-layout')
      didAdd = true
    }

    return () => {
      if (didAdd) {
        document.querySelector('body')?.classList.remove('standalone-layout')
      }
    }
  }, [standalone, showHeader, showFooter])

  const shouldRenderFooter = !standalone && showFooter

  return (
    <>
      <Seo pageId={pageId} shouldRobotsIndex={shouldRobotsIndex} />
      {showTopBar && bannerDescription && <TopBar />}
      {!standalone && showHeader && <Header className={showTopBar && bannerDescription ? 'headerWithBar' : ''} logoOnly={logoOnlyHeader} />}
      {children}
      <CookieConsent />
      <ScrollToTopButton />
      {shouldRenderFooter && <Footer hideNav={noNavFooter} />}
      {!shouldRenderFooter && pageId !== 'signUp' && <RecaptchaTerms textProps={{ center: true }} />}
      <DialogsRenderer />
    </>
  )
}

const Content = ({
  children,
  className,
  padded = false,
  topBorder = false,
  standalone = false,
}) => (
  <main className={cx(
    styles.content,
    padded && styles.padded,
    topBorder && styles.topBorder,
    standalone && styles.standalone,
    className,
  )}
  >
    {children}
  </main>
)

Layout.Content = Content

Layout.propTypes = {
  children: PropTypes.node,
  noNavFooter: PropTypes.bool,
  pageId: PropTypes.string,
  showFooter: PropTypes.bool,
  showTopBar: PropTypes.bool,
  showHeader: PropTypes.bool,
  shouldRobotsIndex: PropTypes.bool,
  logoOnlyHeader: PropTypes.bool,
  standalone: PropTypes.bool,
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  padded: PropTypes.bool,
  topBorder: PropTypes.bool,
  standalone: PropTypes.bool,
}

export default Layout
