export const handleVerifyRecaptchaV3 = async (token, userInfo) => {
  try {
    const res = await fetch('/api/recaptcha/v3/verify', {
      method: 'POST',
      body: JSON.stringify({
        token,
        userInfo,
      }),
    })
    const resJson = await res.json()
    return resJson
  } catch (err) {
    return {}
  }
}

export const RECAPTCHA_ERROR_REASONS = {
  tokenMissing: 'tokenMissing',
  responseFailure: 'responseFailure',
  networkError: 'networkError',
  scoreTooLow: 'scoreTooLow',
}

export const RECAPTCHA_ERROR_CODES = {
  [RECAPTCHA_ERROR_REASONS.tokenMissing]: 1,
  [RECAPTCHA_ERROR_REASONS.responseFailure]: 2,
  [RECAPTCHA_ERROR_REASONS.scoreTooLow]: 3,
  [RECAPTCHA_ERROR_REASONS.networkError]: 4,
}
