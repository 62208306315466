import React from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import cx from 'classnames'

import { Link } from '@/components/common'
import TopNavButton from '@/components/layout/TopNavButton'
import TryFreeCta from '@/components/TryFreeCta'
import { WEB_APP_URL } from '@/config'
import { URLS } from '@/constants'
import routes from '@/routes'
import headerLogoImg from '@/assets/header-logo.svg'

import MobileHeaderNav from './MobileHeaderNav'
import styles from './Header.module.scss'

const mainNavItems = [
  {
    name: 'product',
    to: '#',
    menu: [
      { name: 'neatFeatures' },
      { name: 'automatedInsights' },
      { name: 'vipService' },
    ],
  },
  {
    name: 'features',
    to: '#',
    menu: [
      { name: 'trackReceipts' },
      { name: 'transactionStreaming' },
      { name: 'taxPrep' },
      { name: 'integrations' },
      { name: 'security' },
      { name: 'receiptDocumentScanners' },
      { name: 'expenseManagementMobileApp' },
    ],
  },
  { name: 'pricing' },
  {
    name: 'learn',
    to: '#',
    menu: [
      { name: 'blog' },
      { name: 'webinars' },
      { name: 'helpCenter', to: URLS.support.home, external: true },
    ],
  },
]

export default function Header({ logoOnly, className }) {
  return (
    <div className={cx(styles.header, className)}>
      <div className={styles.content}>
        <Link className={styles.logo} to={routes.index}>
          <Image
            alt="Neat"
            height={24}
            src={headerLogoImg}
            style={{ maxWidth: '140px' }}
            width={92}
          />
        </Link>
        {logoOnly ||
        <>
          <div className={styles.nav}>
            {mainNavItems.map(item => <TopNavButton key={item.name} {...item} />)}
            <Link
              external
              messageId="cta.signIn"
              round={false}
              to={WEB_APP_URL}
              variant="secondary"
            />
            <TryFreeCta
              data-testid="header-try-neat-free"
              messageId="cta.tryNeatFREE"
              round={false}
              size="medium"
            />
          </div>
          <div className={styles.mobileHeaderNav}>
            <MobileHeaderNav items={mainNavItems} />
          </div>
        </>}
      </div>
    </div>
  )
}

Header.propTypes = {
  logoOnly: PropTypes.bool,
  className: PropTypes.string,
}
