import React, { useCallback, useState, useContext, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useAlerts, userAgent } from '@theneatcompany/nui'
import { load } from '@fingerprintjs/botd'
import { usePathname } from 'next/navigation'
import { useIntl } from 'react-intl'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import rollbar, { sendError, trackSignUpPerson, sendWarning } from '@/utils/rollbar'
import { DEFAULT_PLAN_SKU, NEAT_HOME_TRIAL_SKU, UPSELL_PLAN, UPSELL_COUPON, RECAPTCHA_V3_ACTIONS } from '@/constants'
import { WEB_APP_URL, SHOW_UPSELL, SHOW_NO_CC_TRIAL_BACKUP_OFFER } from '@/config'
import { handleVerifyRecaptchaV3 } from '@/utils/recaptcha'
import { ERROR_TYPES } from '@/utils/errors'

const SignUpContext = React.createContext()

export const useSignUp = () => useContext(SignUpContext)

const STEPS = {
  authenticate: 'authenticate',
  existingUser: 'existingUser',
  resetPassword: 'resetPassword',
  upsell: 'upsell',
  newUser: 'newUser',
  selectPlan: 'selectPlan',
  billing: 'billing',
}

export const PAYMENT_METHOD_TYPES = {
  ccPayment: 'ccPayment',
  paypalPayment: 'paypalPayment',
}

const ORDER_FAILURE_MESSAGE = 'Sorry, we had a problem processing your order. Please re-check your information and try again.'
const errorsToIgnore = ['Bad Request', 'Internal Server Error']

const getForceOrderRequestValidationErrorCode = () => {
  try {
    const score = localStorage.getItem('forceOrderRequestValidationErrorCode')
    return score
  } catch {
    return undefined
  }
}

const SignUpProvider = ({ children }) => {
  const [startedCheckout, setStartedCheckout] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [navigation, setNavigation] = useState({ steps: STEPS, currentStep: STEPS.authenticate })
  const [user, setUser] = useState({})
  const [plan, setPlan] = useState({})
  const [coupon, setCoupon] = useState(null)
  const [utm, setUtm] = useState({})
  const [billingData, setBillingData] = useState({})
  const [canPurchasePlan, setCanPurchasePlan] = useState(false)
  const [isLinkedToGoogle, setIsLinkedToGoogle] = useState(false)
  const [order, setOrder] = useState({})
  const [csrfToken, setCsrfToken] = useState(null)

  const [failedAuth, setFailedAuth] = useState(null)
  const [isOrderProcessing, setIsOrderProcessing] = useState(false)
  const [isOrderRequestProcessing, setIsOrderRequestProcessing] = useState(false)
  const [isPayPalOpen, setIsPayPalOpen] = useState(false)
  const [orderError, setOrderError] = useState()

  const [showNoCcTrial, setShowNoCcTrial] = useState(false)

  const [recurlyFormValidation, setRecurlyFormValidation] = useState({})
  const [recurlyToken, setRecurlyToken] = useState()
  const [recurlyErrors, setRecurlyErrors] = useState()

  const [formHolderHeight, setFormHolderHeight] = useState(0)
  const [formBoxHeight, setFormBoxHeight] = useState(0)

  const [isRequestingPasswordReset, setIsRequestingPasswordReset] = useState(false)

  const botdRef = useRef()
  const userValidation = useRef()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const alerts = useAlerts()

  const pathname = usePathname()
  const { formatMessage } = useIntl()

  const {
    query: {
      utm_source: utmSource = false,
      utm_medium: utmMedium = false,
      utm_campaign: utmCampaign = false,
      sku = '',
      couponCode = '',
    },
    isReady,
    push,
  } = useRouter()

  const handleExecuteRecaptcha = useCallback(async (action) => {
    if (!executeRecaptcha) {
      sendWarning(null, `SignUpProvider: executeRecaptcha not available: ${action}`)
      return undefined
    }
    const token = await executeRecaptcha(action)
    return token
  }, [executeRecaptcha])

  const clearCheckout = () => {
    alerts.clear()

    setNavigation({ steps: STEPS, currentStep: STEPS.authenticate })
    setUser({})
    setPlan({})
    setCoupon({})
    setCanPurchasePlan(false)
    setIsLinkedToGoogle(false)
    setOrder({})
    setCsrfToken(null)
    setBillingData({})
    setFailedAuth(null)
    setIsOrderProcessing(false)
    setIsPayPalOpen(false)
    setOrderError(null)
    setRecurlyFormValidation({})
    setRecurlyToken(null)
    setRecurlyErrors(null)

    userValidation.current = null
  }

  useEffect(() => {
    if (pathname !== '/t' && pathname !== '/t/success' && startedCheckout) {
      rollbar.info(`Checkout exited at step: ${navigation.currentStep}`, {})
      clearCheckout()
    }
    setStartedCheckout(pathname === '/t')
  }, [pathname])

  const fetchPlan = async (planLookup) => {
    try {
      const res = await fetch('/api/signup/plan', {
        method: 'POST',
        body: JSON.stringify({ plan: planLookup }),
      })

      const planRes = await res.json()

      if (planRes.status && planRes.status >= 300) {
        throw Error('Error status > 300 in API response')
      }
      setPlan(planRes)

      return planRes
    } catch (error) {
      alerts.error('Error Fetching Plan')
      sendError(error, `Error fetching /api/signup/plan: ${planLookup}`)
      return null
    }
  }

  useEffect(() => {
    load()
      .then((botd) => {
        botdRef.current = botd
      })
      .catch((error) => {
        rollbar.error('Bot Detection failed to load', error)
      })
  }, [])

  const detectBot = async (email) => {
    if (!botdRef?.current) {
      rollbar.info(`Bot Detection service not available for ${email} at step: ${navigation.currentStep}`)
      return
    }
    try {
      const botDetection = await botdRef.current.detect()
      rollbar.info(`Bot Detection for ${email} at step: ${navigation.currentStep}: ${botDetection.bot} `)
    } catch (error) {
      rollbar.error(`Bot Detection failed for ${email}`, error)
    }
  }

  const navigateToStep = (step) => {
    setNavigation({ ...navigation, ...{ currentStep: navigation.steps[step] } })
  }

  const shouldSkipPlanSelect = useMemo(() => !!(plan.isTrial || plan.sku === 'vault50' || plan.sku?.startsWith('neathome')), [plan])

  const shouldShowUpsell = plan.sku === NEAT_HOME_TRIAL_SKU && SHOW_UPSELL

  const bannerDescription = useMemo(() => coupon?.utmCampaigns?.find(
    campaign => campaign.name === utm?.campaign?.toLowerCase(),
  )?.description ?? null, [utm, coupon])

  useEffect(() => {
    const fetchCouponByCampaign = async (campaign) => {
      const res = await fetch('/api/signup/coupon/campaign', {
        method: 'POST',
        body: JSON.stringify({ campaign }),
      })

      const campaignCoupon = await res.json()
      if (res.status && res.status > 300) {
        alerts.error('Error Fetching Coupon')
        return null
      }

      setCoupon(campaignCoupon)
      return campaignCoupon
    }

    if (utmSource || utmMedium || utmCampaign) {
      setUtm({
        source: utmSource,
        medium: utmMedium,
        campaign: utmCampaign,
      })
    }

    if (utmCampaign) {
      fetchCouponByCampaign(utmCampaign)
    }
  }, [utmSource, utmMedium, utmCampaign])

  const fetchCoupon = async (promoCode) => {
    try {
      const res = await fetch('/api/signup/coupon', {
        method: 'POST',
        body: JSON.stringify({ promoCode }),
      })

      const couponRes = await res.json()
      if (res.status > 300 || couponRes.status > 300) {
        throw Error('The promo code is not valid.')
      }

      if (couponRes.plans?.length) {
        const matchingPlans = couponRes.plans.some(couponPlan => couponPlan.sku === plan.sku)
        if (!matchingPlans) {
          setCoupon({})
          throw Error('Coupon is not valid with this plan')
        }
      }

      setCoupon(couponRes)
    } catch (error) {
      sendError(error, `Error fetching /api/signup/coupon: ${promoCode}`)
      throw error
    }
  }

  useEffect(() => {
    if (couponCode && plan?.sku) {
      try {
        fetchCoupon(couponCode)
      } catch (err) {
        alerts.error('Error Fetching Coupon')
        sendError(err, 'Error fetching /api/signup/coupon')
      }
    }
  }, [couponCode, plan])

  useEffect(() => {
    if (!isReady) return

    const fetchPlanAsync = async (planSku) => {
      setIsLoading(true)
      await fetchPlan(planSku)
      setIsLoading(false)
    }

    let planSku = DEFAULT_PLAN_SKU
    if (sku) {
      planSku = sku
    }

    fetchPlanAsync(planSku)
  }, [sku, isReady])

  const handleForgotPassword = useCallback(async () => {
    const { neatUserId } = user
    try {
      setIsRequestingPasswordReset(true)
      const token = await handleExecuteRecaptcha(RECAPTCHA_V3_ACTIONS.SIGNUP_RESET_PASSWORD_REQUEST)
      const userInfo = { ...user, id: neatUserId }
      delete userInfo.neatUserId
      await handleVerifyRecaptchaV3(token, userInfo)

      const res = await fetch('/api/signup/resetPassword', {
        method: 'POST',
        body: JSON.stringify({ neatUserId }),
      })
      const resMessage = await res.json()
      if (res.status > 300) {
        alerts.error('Error Resetting Password')
        sendError(resMessage, 'Error fetching /api/signup/resetPassword')
      } else {
        navigateToStep(navigation.steps.resetPassword)
      }
    } catch (err) {
      sendError(err, 'Error fetching /api/signup/resetPassword')
      alerts.error('Error Resetting Password')
    } finally {
      setIsRequestingPasswordReset(false)
    }
  }, [user])

  useEffect(() => {
    const el = document.querySelector('html')
    if (el) el.scrollTop = 0
  }, [navigation.currentStep])

  const createLead = async ({ firstName, lastName, email, phone }, step) => {
    try {
      await fetch('/api/lead/create', {
        method: 'POST',
        body: JSON.stringify(
          {
            email: email ?? user.email,
            firstName: firstName ?? user.firstName,
            lastName: lastName ?? user.lastName,
            phone: phone ?? user.phone,
            source: 'Abandoned Cart',
          },
        ),
      })
    } catch (error) {
      sendError(error, `Failed to create lead: Sign Up Step: ${step} `)
    }
  }

  const validateUser = async ({ email, googleId = null }) => {
    try {
      const res = await fetch('/api/signup/validate', {
        method: 'POST',
        body: JSON.stringify({
          user: {
            email,
            ...(googleId && { googleId }),
          },
        }),
      })

      const resMessage = await res.json()
      userValidation.current = resMessage

      setUser({ ...user, ...resMessage.user })
      setIsLinkedToGoogle(!!resMessage.user?.isLinkedToGoogle)
      return resMessage
    } catch (err) {
      sendError(err, 'Error fetching /api/signup/validate')
      return { status: 0 }
    }
  }

  const handleOrderResponse = async (res) => {
    const resMessage = await res.json()

    setOrder(resMessage)

    if (resMessage.type === ERROR_TYPES.requestValidationFailed) {
      if ([3, 4].includes(resMessage.error_code) && SHOW_NO_CC_TRIAL_BACKUP_OFFER) {
        setShowNoCcTrial(true)
      } else {
        setShowNoCcTrial(false)
        setOrderError(formatMessage({ id: 'pages.signUp.requestValidationError' }))
      }
    } else if (resMessage.error) {
      if (resMessage.status >= 500 || errorsToIgnore.includes(resMessage.error?.message)) {
        setRecurlyErrors(ORDER_FAILURE_MESSAGE)
      } else {
        setOrderError(resMessage.error?.message)
      }
    } else if (resMessage.status >= 300) {
      if (errorsToIgnore.includes(resMessage.message)) {
        setRecurlyErrors(ORDER_FAILURE_MESSAGE)
      } else {
        setOrderError(resMessage.message)
      }
    } else if (res.status >= 400) {
      setOrderError('Your session has expired.')
    } else {
      alerts.clear()
      push('/t/success')
    }
  }

  const handleCreateNoCcTrialOrder = async () => {
    alerts.clear()

    setIsOrderProcessing(true)
    setIsOrderRequestProcessing(true)

    const skuMap = {
      neatyr: 'neatnocc',
      neatyrt: 'neatnocc',
      neatvipyr: 'neatnocc',
      neataiyr: 'neatainocc',
      neataivipyr: 'neatainocc',
      neathomeyr: 'neathomenocc',
      neathomevipyr: 'neathomenocc',
    }

    try {
      const res = await fetch('/api/signup/createTrial', {
        method: 'POST',
        headers: { neat: csrfToken },
        body: JSON.stringify({
          user: { ...user, address: { country: billingData.billingCountry, postalCode: billingData.billingZipCode } },
          plan: { sku: skuMap[plan.sku] },
          ...(coupon?.code && { coupon: { code: coupon.code } }),
          source: 'neat.com order error no-cc trial',
          device: { os: userAgent.device?.model, type: userAgent.browser?.name },
          utm,
        }),
      })
      await handleOrderResponse(res)
    } catch (err) {
      sendError(err, 'Error Creating Order Error No CC Trial Order')
    } finally {
      setShowNoCcTrial(false)
      setIsOrderProcessing(false)
      setIsOrderRequestProcessing(false)
    }
  }

  const handleCreateOrder = async () => {
    if (isOrderRequestProcessing) return

    alerts.clear()
    setIsOrderRequestProcessing(true)

    const recaptchaV3Token = await handleExecuteRecaptcha(RECAPTCHA_V3_ACTIONS.SIGNUP_CREATE_ORDER)
    const forceOrderRequestValidationErrorCode = getForceOrderRequestValidationErrorCode()

    try {
      const res = await fetch('/api/signup/create', {
        method: 'POST',
        headers: { neat: csrfToken },
        body: JSON.stringify({
          user: { ...user, address: { country: billingData.billingCountry, postalCode: billingData.billingZipCode } },
          plan,
          ...(coupon?.code && { coupon: { code: coupon.code } }),
          source: plan.isTrial ? 'neat.com Trial' : 'Checkout',
          paymentMethod: { token: recurlyToken, type: billingData.paymentMethod },
          device: { os: userAgent.device?.model, type: userAgent.browser?.name },
          utm,
          rId3: recaptchaV3Token,
          ...forceOrderRequestValidationErrorCode?.length > 0 ? { forceOrderRequestValidationErrorCode } : {},
        }),
      })

      await handleOrderResponse(res)
    } catch (err) {
      setRecurlyErrors(ORDER_FAILURE_MESSAGE)
      sendError(err, 'Error Creating Order')
    } finally {
      setIsOrderProcessing(false)
      setIsOrderRequestProcessing(false)
    }
  }

  useEffect(() => {
    if (recurlyToken && isOrderProcessing) {
      handleCreateOrder()
    }
  }, [recurlyToken, isOrderProcessing])

  useEffect(() => {
    if (recurlyErrors && isOrderProcessing) {
      setIsOrderProcessing(false)
      setOrderError(recurlyErrors)
    }
  }, [recurlyErrors, isOrderProcessing])

  const saveStepBilling = async (model) => {
    setBillingData(model)
    setRecurlyErrors(null)

    await detectBot(user.email)
    setIsOrderProcessing(true)
  }

  const saveStepAuthenticate = async (userData, googleId) => {
    if (googleId) {
      const { email: googleEmail, family_name: lastName, given_name: firstName } = JSON.parse(googleId.split('.').map(part => Buffer.from(part, 'base64').toString())[1])
      userData.googleId = googleId
      userData.email = googleEmail
      userData.firstName = firstName
      userData.lastName = lastName
    }

    await detectBot(userData.email)

    const token = await handleExecuteRecaptcha(RECAPTCHA_V3_ACTIONS.SIGNUP_EMAIL)
    await handleVerifyRecaptchaV3(token, userData)
    const validation = await validateUser(userData)

    userData.neatUserId = validation.user?.neatUserId
    if (!userData.firstName && validation.user?.firstName) userData.firstName = validation.user.firstName
    if (!userData.lastName && validation.user?.lastName) userData.lastName = validation.user.lastName

    setCanPurchasePlan(validation.status)

    if (validation.user) {
      trackSignUpPerson({
        email: userData.email,
        ...userData,
        ...validation.user,
      })
    }

    await createLead({ email: userData.email }, navigation.currentStep)

    setUser(userData)

    if (userData.googleId) {
      if (userData.neatUserId) {
        // Check if User can purchase plan and is already linked
        if (validation.status === 1 && validation.isLinkedToGoogle === true) {
          if (shouldShowUpsell) {
            navigateToStep(navigation.steps.upsell)
          } else {
            navigateToStep(navigation.steps.billing)
          }
        } else {
          navigateToStep(navigation.steps.existingUser)
        }
      } else if (validation.status === 1) {
        if (shouldShowUpsell) {
          navigateToStep(navigation.steps.upsell)
        } else if (shouldSkipPlanSelect) {
          navigateToStep(navigation.steps.newUser)
        } else {
          navigateToStep(navigation.steps.selectPlan)
        }
      } else {
        alerts.error(formatMessage({ id: 'pages.signUp.unexpectedError' }))
      }
    } else if (validation.user?.neatUserId) {
      navigateToStep(navigation.steps.existingUser)
    } else if (validation.status === 1) {
      if (shouldShowUpsell) {
        navigateToStep(navigation.steps.upsell)
      } else if (shouldSkipPlanSelect) {
        navigateToStep(navigation.steps.newUser)
      } else {
        navigateToStep(navigation.steps.selectPlan)
      }
    } else {
      alerts.error(formatMessage({ id: 'pages.signUp.unexpectedError' }))
    }
  }

  const saveStepSelectPlan = async (planSku) => {
    await fetchPlan(planSku)

    if (user.neatUserId) {
      navigateToStep(navigation.steps.billing)
    } else {
      navigateToStep(navigation.steps.newUser)
    }
  }

  const requirePassword = () => {
    if (user.googleId) {
      if (isLinkedToGoogle) return false
    }

    return true
  }

  const handleAuth = async ({ email, password }, action) => {
    try {
      let rId
      if (action) {
        rId = await handleExecuteRecaptcha(action)
      }
      const res = await fetch('/api/signup/auth', {
        method: 'POST',
        body: JSON.stringify({ email, password, rId }),
      })
      const resMessage = await res.json()
      return resMessage
    } catch (err) {
      sendError(err, 'Error fetching /api/signup/coupon')
      alerts.error('Error authenticating')
    }

    return { authenticated: false }
  }

  const saveStepExistingUser = async (model) => {
    const passwordRequired = requirePassword()
    if (passwordRequired) {
      const authResponse = await handleAuth(model, RECAPTCHA_V3_ACTIONS.SIGNUP_EXISTING_USER)
      if (authResponse.authenticated) {
        setUser({ ...user, password: model.password })
        if (canPurchasePlan) {
          if (shouldSkipPlanSelect) {
            navigateToStep(navigation.steps.billing)
          } else {
            navigateToStep(navigation.steps.selectPlan)
          }
        } else {
          window.location.assign(authResponse.ssoUrl)
        }
      } else {
        setFailedAuth('Your login is incorrect. Please try again.')
      }
    } else if (user.googleId && !canPurchasePlan) {
      window.location.assign(`${WEB_APP_URL}/login?g_token_id=${user.googleId}`)
    } else {
      alerts.error('There was an error authenticating. Please try again')
    }
  }

  const saveStepResetPassword = async (model) => {
    const { password } = model
    if (!user.email) {
      alerts.error('Please provide an email or login with Google')
    } else {
      const authResponse = await handleAuth(
        { email: user.email, password: model.password }, RECAPTCHA_V3_ACTIONS.SIGNUP_RESET_PASSWORD_AUTHENTICATE,
      )

      if (authResponse.authenticated) {
        const validation = await validateUser(user)

        if (validation.status === 1) {
          user.password = password
          setUser(user)
          if (shouldSkipPlanSelect) {
            navigateToStep(navigation.steps.billing)
          } else if (shouldShowUpsell) {
            navigateToStep(navigation.steps.upsell)
          } else {
            navigateToStep(navigation.steps.selectPlan)
          }
        } else if (validation.user?.neatUserId) {
          window.location.assign(authResponse.ssoUrl)
        }
      } else {
        alerts.error('Authentication Failed')
      }
    }
  }

  const saveStepUpsell = async (acceptOffer) => {
    if (acceptOffer) {
      await fetchPlan(UPSELL_PLAN)
      await fetchCoupon(UPSELL_COUPON)
    }
    navigateToStep(navigation.steps.newUser)
  }

  const saveStepNewUser = async (userData) => {
    setUser({ ...user, ...userData })

    const token = await handleExecuteRecaptcha(RECAPTCHA_V3_ACTIONS.SIGNUP_PERSONAL_INFO)
    const dataCopy = { ...userData, email: user.email }
    delete dataCopy.password
    await handleVerifyRecaptchaV3(token, dataCopy)
    await createLead({ email: userData.email }, navigation.currentStep)

    navigateToStep(navigation.steps.billing)
  }

  return (
    <SignUpContext.Provider value={{
      navigation,
      user,
      plan,
      coupon,
      canPurchasePlan,
      formBoxHeight,
      formHolderHeight,
      order,
      isLoading,
      bannerDescription,
      recurlyFormValidation,
      isOrderProcessing,
      billingData,
      recurlyErrors,
      orderError,
      failedAuth,
      isPayPalOpen,
      isRequestingPasswordReset,
      showNoCcTrial,
      setIsPayPalOpen,
      setCoupon,
      fetchCoupon,
      setFormBoxHeight,
      setFormHolderHeight,
      handleForgotPassword,
      setRecurlyFormValidation,
      saveStepBilling,
      setRecurlyToken,
      setRecurlyErrors,
      saveStepAuthenticate,
      saveStepSelectPlan,
      requirePassword,
      saveStepExistingUser,
      saveStepResetPassword,
      saveStepUpsell,
      saveStepNewUser,
      setIsOrderProcessing,
      setCsrfToken,
      handleCreateNoCcTrialOrder,
    }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

SignUpProvider.propTypes = {
  children: PropTypes.node,
}

export default SignUpProvider
