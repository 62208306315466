import { capitalizeFirstLetter } from '@/utils/string'

export const DEFAULT_MESSAGE = 'Request error'

export const ERROR_TYPES = {
  requestValidationFailed: 'request_validation_failed',
}

export const extractMessage = (error) => {
  if (!error || error.status === 500) {
    return DEFAULT_MESSAGE
  }

  if (error.status === 422 && error.attributes) {
    const messages = Object.values(error.attributes)
    // @TODO Clarify how we should handle the case of multiple attributes messages, since nui alerts.error is not expecting an array
    return messages.length === 1 ? capitalizeFirstLetter(messages[0]) : messages.map(capitalizeFirstLetter)
  }

  return typeof error.message === 'string' && error.message.trim().length > 0 ? capitalizeFirstLetter(error.message) : DEFAULT_MESSAGE
}

export default {}
